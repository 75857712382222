import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import {
  MutationStatusCode,
  UpsertTenantAccountStatus,
  UpsertTenantAccountMutationVariables,
  CountryCode,
} from '../gql-types.generated';
import { getStandardHeaders } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_TENANT_ACCOUNT = gql`
  mutation upsertTenantAccount($input: UpsertTenantAccountInput!) {
    upsertTenantAccount(input: $input) {
      code
      message
    }
  }
`;

export const mutationUpsertTenant = async (
  client: ApolloClient<NormalizedCacheObject>,
  accountName: string,
  accountDescription: string,
  statementDescription: string,
  refundPolicy: string,
  country: CountryCode,
  token: string,
  supportEmail: string,
): Promise<UpsertTenantAccountStatus | undefined> => {
  const headers = await getStandardHeaders();
  const { data, errors } = await client.mutate<
    { upsertTenantAccount: UpsertTenantAccountStatus },
    UpsertTenantAccountMutationVariables
  >({
    variables: { input: { accountName, accountDescription, statementDescription, refundPolicy, country, token, supportEmail } },
    mutation: MUTATION_UPSERT_TENANT_ACCOUNT,
    context: {
      headers,
    },
  });
  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the tenant upsert.');
  }

  if (
    data &&
    (data.upsertTenantAccount.code === MutationStatusCode.Pending || data.upsertTenantAccount.code === MutationStatusCode.Success)
  ) {
    return data.upsertTenantAccount;
  }

  return undefined;
};

export const mutationUpdateTenant = async (
  client: ApolloClient<NormalizedCacheObject>,
  token: string,
): Promise<UpsertTenantAccountStatus | undefined> => {
  const headers = await getStandardHeaders();
  const { data, errors } = await client.mutate<
    { upsertTenantAccount: UpsertTenantAccountStatus },
    UpsertTenantAccountMutationVariables
  >({
    variables: { input: { token } },
    mutation: MUTATION_UPSERT_TENANT_ACCOUNT,
    context: {
      headers,
    },
  });
  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the tenant upsert.');
  }

  if (
    data &&
    (data.upsertTenantAccount.code === MutationStatusCode.Pending || data.upsertTenantAccount.code === MutationStatusCode.Success)
  ) {
    return data.upsertTenantAccount;
  }

  return undefined;
};
