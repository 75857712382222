/* eslint-disable @typescript-eslint/naming-convention */
export const MSAL_CLIENT_ID = `${process.env.REACT_APP_MSAL_CLIENTID}`;
export const MSAL_AUTHORITY = `${process.env.REACT_APP_MSAL_AUTHORITY}`;
export const MSAL_READ_SCOPE = `${process.env.REACT_APP_READ_SCOPE}`;
export const MSAL_WRITE_SCOPE = `${process.env.REACT_APP_WRITE_SCOPE}`;
export const WEPAY_ENVIRONMENT = `${process.env.REACT_APP_WEPAY_ENVIRONMENT}`;
export const WEPAY_APP_ID = `${process.env.REACT_APP_WEPAY_APP_ID}`;
export const WEPAY_API_VERSION = `${process.env.REACT_APP_WEPAY_API_VERSION}`;
export const AI_INSTRUMENTATION_KEY = `${process.env.REACT_APP_AI_INSTRUMENTATION_KEY}`;
export const REPORT_TIME_INTERVAL = `${process.env.REACT_APP_REPORT_TIME_INTERVAL}`;
export const REPORT_TIME_LIMIT = `${process.env.REACT_APP_REPORT_TIME_LIMIT}`;
export const ERP_PRODUCT_ID = `${process.env.REACT_APP_ERP_PRODUCT_ID}`;
export const APTEAN_JS_SDK_URL = `${process.env.REACT_APP_APTEAN_JS_SDK_URL}`;
export const APTEAN_API_SUBSCRIPTION_KEY = `${process.env.REACT_APP_APTEAN_API_SUBSCRIPTION_KEY}`;
export const MUI_LICENSE_KEY = `${process.env.REACT_APP_MUI_LICENSE_KEY}`;
export const PAYOUT_TIME_INTERVAL = `${process.env.REACT_APP_PAYOUT_TIME_INTERVAL || 1000}`;
export const PTT_ONBOARD_IFRAME_URL = `${process.env.REACT_APP_PTT_ONBOARD_IFRAME_URL}`;
export const PTT_ONBOARD_MESSAGE_ORIGIN = `${process.env.REACT_APP_PTT_ONBOARD_MESSAGE_ORIGIN}`;
