import { IMetricTelemetry } from '@microsoft/applicationinsights-web';
import React, { useEffect } from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../util/TelemetryService';

interface TelemetryProviderProps {
  cssClassName: string;
}

const TelemetryProvider: React.FC<TelemetryProviderProps> = ({ cssClassName, children }) => {
  let mountTimestamp = 0;
  let firstActiveTimestamp = 0;
  let idleStartTimestamp = 0;
  let lastActiveTimestamp = 0;
  let totalIdleTime = 0;
  let idleCount = 0;
  const idleTimeout = 5000;
  let intervalId: NodeJS.Timeout;

  const trackActivity = (_e: React.SyntheticEvent<unknown>): void => {
    if (firstActiveTimestamp === 0) {
      firstActiveTimestamp = Date.now();
      lastActiveTimestamp = firstActiveTimestamp;
    } else {
      lastActiveTimestamp = Date.now();
    }

    if (idleStartTimestamp > 0) {
      const lastIdleTime = lastActiveTimestamp - idleStartTimestamp;
      totalIdleTime += lastIdleTime;
      idleStartTimestamp = 0;
    }
  };

  const getEngagementTimeSeconds = () => {
    return (Date.now() - firstActiveTimestamp - totalIdleTime - idleCount * idleTimeout) / 1000;
  };

  useEffect(() => {
    mountTimestamp = Date.now();
    firstActiveTimestamp = 0;
    totalIdleTime = 0;
    lastActiveTimestamp = 0;
    idleStartTimestamp = 0;
    idleCount = 0;

    intervalId = setInterval(() => {
      if (lastActiveTimestamp > 0 && idleStartTimestamp === 0 && Date.now() - lastActiveTimestamp >= idleTimeout) {
        idleStartTimestamp = Date.now();
        idleCount += 1;
      }
    }, 100);

    return () => {
      if (mountTimestamp === 0) {
        throw new Error('TelemetryProvider:componentWillUnmount: mountTimestamp is not initialized.');
      }
      if (intervalId) {
        clearInterval(intervalId);
      }

      if (firstActiveTimestamp === 0) {
        return;
      }

      const engagementTime = getEngagementTimeSeconds();
      const metricData: IMetricTelemetry = {
        average: engagementTime,
        name: 'React Component Engaged Time (seconds)',
        sampleCount: 1,
      };

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const additionalProperties: { [key: string]: string } = { 'Component Name': 'TelemetryProvider' };
      reactPlugin.trackMetric(metricData, additionalProperties);
    };
  }, []);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <div
        onKeyDown={trackActivity}
        onMouseMove={trackActivity}
        onScroll={trackActivity}
        onMouseDown={trackActivity}
        onTouchStart={trackActivity}
        onTouchMove={trackActivity}
        className={cssClassName}
      >
        {children}
      </div>
    </AppInsightsContext.Provider>
  );
};

// export default withAITracking(ai.reactPlugin, TelemetryProvider, 'TelemetryProvider');
export default TelemetryProvider;
