import { Box, Button, CircularProgress, Divider, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import OnboardLegalEntity from '../../components/OnboardLegalEntity';
import OnboardMerchantAccount from '../../components/OnboardMerchantAccount';
import OnboardTos from '../../components/OnboardTos';
import { CountryCode } from '../../gql-types.generated';
import { KycIframeLib } from '../../util/WePay';
import { selectNetworkBusy } from '../app/AppSlice';
import { selectTenantAccount } from '../app/AppSlice';
import {
  captureTenantAccountCountry,
  captureLegalEntityToken,
  captureTenantAccountDescription,
  captureTenantAccountName,
  captureTenantAccountStatement,
  captureMerchantRefundPolicy,
  captureTOSSigner,
  persistMerchantOnboardingData,
} from './MerchantOnboardingActions';
import {
  selectTenantAccountCountry,
  selectLegalEntityToken,
  selectMerchantDescription,
  selectMerchantName,
  selectMerchantRefundPolicy,
  selectMerchantStatement,
  selectTOSSignerName,
  selectSupportEmail,
  captureSupportEmail,
} from './MerchantOnboardingSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 768,
      alignSelf: 'center',
    },
    stepper: {
      padding: theme.spacing(3, 4),
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      color: theme.palette.success.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

const getSteps = (): string[] => ['legalEntity', 'merchantAccount', 'tos'];
let tokenizeFnRef: () => Promise<{ id: string }>;

const MerchantOnboard: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const tenantAccount = useSelector(selectTenantAccount);
  const tenantAccountCountry = useSelector(selectTenantAccountCountry);
  const legalEntityToken = useSelector(selectLegalEntityToken);
  const merchantName = useSelector(selectMerchantName);
  const merchantDescription = useSelector(selectMerchantDescription);
  const merchantStatement = useSelector(selectMerchantStatement);
  const refundPolicy = useSelector(selectMerchantRefundPolicy);
  const tosSignedName = useSelector(selectTOSSignerName);
  const supportEmail = useSelector(selectSupportEmail);
  const busy = useSelector(selectNetworkBusy);
  const dispatch = useDispatch();
  const [isSignerNameValid, setSignerNameValid] = useState(true);
  const [isMerchantNameValid, setMerchantNameValid] = useState(true);
  const [isMerchantDescriptionValid, setMerchantDescriptionValid] = useState(true);
  const [isMerchantStatementValid, setMerchantStatementValid] = useState(true);
  const [isSupportEmailValid, setSupportEmailValid] = useState(true);

  if (tenantAccount?.tosAcceptance) {
    return <Redirect to="/" />;
  }

  const onMerchantOnboardComplete = async (
    country: CountryCode,
    legalEntityToken: string,
    merchantName: string,
    merchantDescription: string,
    merchantStatement: string,
    refundPolicy: string,
    supportEmail: string,
  ): Promise<void> => {
    dispatch(
      persistMerchantOnboardingData(
        country,
        legalEntityToken,
        merchantName,
        merchantDescription,
        merchantStatement,
        refundPolicy,
        supportEmail,
      ),
    );
  };
  const handleNext = async (): Promise<void> => {
    if (activeStep === 0 && tenantAccountCountry) {
      try {
        const token = await tokenizeFnRef();
        dispatch(captureLegalEntityToken(token.id));
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } catch (error) {
        // TODO: need better handling
        console.error('Unable to tokenize kyc iframe', error);
      }
      return;
    }
    const emailFormat = RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/); //used to check support email format.
    if (
      activeStep === 1 &&
      merchantName &&
      merchantDescription &&
      merchantStatement &&
      supportEmail &&
      !!emailFormat.test(supportEmail)
    ) {
      setMerchantNameValid(true);
      setMerchantDescriptionValid(true);
      setMerchantStatementValid(true);
      setSupportEmailValid(true);
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      return;
    }
    if (activeStep === 1) {
      if (!merchantName) {
        setMerchantNameValid(false);
      }
      if (!merchantDescription) {
        setMerchantDescriptionValid(false);
      }
      if (!merchantStatement) {
        setMerchantStatementValid(false);
      }
      if (!supportEmail || !emailFormat.test(supportEmail)) {
        setSupportEmailValid(false);
      }
      return;
    }
    if (activeStep === 2 && !tosSignedName) {
      setSignerNameValid(false);
      return;
    }
    if (activeStep === 2) {
      setSignerNameValid(true);
    }
    await onMerchantOnboardComplete(
      tenantAccountCountry,
      legalEntityToken as string,
      merchantName as string,
      merchantDescription as string,
      merchantStatement as string,
      refundPolicy as string,
      supportEmail as string,
    );
    history.push('/');
  };
  const handleCountrySelection = (country: CountryCode) => {
    dispatch(captureTenantAccountCountry(country));
  };
  const getTokenizeFnReference = (iframeRef: KycIframeLib) => {
    tokenizeFnRef = iframeRef.tokenize;
  };
  const handleMerchantNameInput = (name: string) => {
    setMerchantNameValid(!!name && !!name.trim());
    dispatch(captureTenantAccountName(name));
  };
  const handleMerchantDescriptionInput = (description: string) => {
    setMerchantDescriptionValid(!!description && !!description.trim());
    dispatch(captureTenantAccountDescription(description));
  };
  const handleMerchantStatementInput = (statement: string) => {
    setMerchantStatementValid(!!statement && !!statement.trim());
    dispatch(captureTenantAccountStatement(statement));
  };
  const handleRefundPolicyInput = (refundPolicy: string) => {
    dispatch(captureMerchantRefundPolicy(refundPolicy));
  };
  const handleTosNameInput = (name: string) => {
    setSignerNameValid(!!name && !!name.trim());
    dispatch(captureTOSSigner(name));
  };
  const handleSupportEmailInput = (email: string) => {
    const emailFormat = RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
    setSupportEmailValid(!!emailFormat.test(email));
    dispatch(captureSupportEmail(email));
  };

  let stepToRender = (
    <OnboardLegalEntity
      handleCountrySelection={handleCountrySelection}
      legalEntityCountry={tenantAccountCountry}
      getTokenizeFnRef={getTokenizeFnReference}
      isOnboarding={true}
      ssn4Enabled={true}
    />
  );

  if (activeStep === 1) {
    stepToRender = (
      <OnboardMerchantAccount
        handleMerchantNameInput={handleMerchantNameInput}
        handleMerchantDescriptionInput={handleMerchantDescriptionInput}
        handleMerchantStatementInput={handleMerchantStatementInput}
        handleRefundPolicyInput={handleRefundPolicyInput}
        handleSupportEmailInput={handleSupportEmailInput}
        merchantName={merchantName}
        merchantDescription={merchantDescription}
        merchantStatement={merchantStatement}
        refundPolicy={refundPolicy}
        isNameFieldEmpty={!isMerchantNameValid}
        isDescriptionFieldEmpty={!isMerchantDescriptionValid}
        isStatementFieldEmpty={!isMerchantStatementValid}
        isSupportEmailValid={!isSupportEmailValid}
      />
    );
  }
  if (activeStep === 2) {
    stepToRender = (
      <OnboardTos
        handleNameInput={handleTosNameInput}
        isSignerFieldEmpty={!isSignerNameValid}
        tenantAccountCountry={tenantAccountCountry}
      />
    );
  }

  return (
    <Paper className={classes.root}>
      <Box pt={4} pb={0} px={4}>
        <Typography variant="subtitle2">SET UP YOUR ACCOUNT</Typography>
        <Typography variant="h5">Let&apos;s get started!</Typography>
      </Box>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Divider />
      {stepToRender}
      <Box px={3} py={2} display="flex" justifyContent="flex-end">
        <div className={classes.wrapper}>
          <Button variant="contained" color="primary" disabled={busy} data-cy="onboard-next" onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
          {busy && <CircularProgress aria-label={'progress spinner'} size={24} className={classes.buttonProgress} />}
        </div>
      </Box>
    </Paper>
  );
};

export default MerchantOnboard;
