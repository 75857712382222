import { gqlClient } from '../../components/AppProvider';
import { mutationUpdateTenant } from '../../gql/MutationUpsertTenantAccount';
import { AppDispatch, AppThunk } from '../../store';
import { watchTenantAccount } from '../app/AppActions';
import { decrementRequestsInFlight, incrementRequestsInFlight } from '../app/AppSlice';
import { fetchMerchantReonboardingError, fetchMerchantReonboardingSuccess } from './MerchantReonboardingSlice';

export const UpdateMerchantTenant =
  (token: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementRequestsInFlight());
    dispatch(fetchMerchantReonboardingError(undefined));
    dispatch(fetchMerchantReonboardingSuccess(false));

    try {
      const tenant = await mutationUpdateTenant(gqlClient, token);
      if (!tenant) throw new Error('[MerchantOnboardingActions] Merchant account not updated');

      console.log('mutationUpsertTenant: ', tenant);

      // refetch merchant account
      dispatch(fetchMerchantReonboardingSuccess(true));
      dispatch(watchTenantAccount());
      dispatch(decrementRequestsInFlight());
    } catch (error) {
      console.error(error);
      dispatch(fetchMerchantReonboardingError(error));
      dispatch(fetchMerchantReonboardingSuccess(false));
      dispatch(decrementRequestsInFlight());
    }
  };
