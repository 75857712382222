import { Box, Typography, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { CountryCode } from '../gql-types.generated';
import { createOnboardingIframe, KycIframeLib, WePayInvalidParamsError } from '../util/WePay';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    separateButtons: {
      letterSpacing: '1.25px',
      padding: theme.spacing(0.75, 2),
      '&:not(:last-child)': {
        borderRadius: theme.spacing(0.5),
      },
      '&:not(:first-child)': {
        borderLeftColor: 'rgba(0, 0, 0, 0.12)',
        borderRadius: theme.spacing(0.5),
        marginLeft: theme.spacing(2),
      },
    },
    rootButton: {
      '&$selectedButton': {
        backgroundColor: theme.palette.uxBlue.selected,
        color: theme.palette.primary.main,
        border: 'none',
      },
    },
    selectedButton: {},
  }),
);

interface OnboardLegalEntityProps {
  getTokenizeFnRef: (iframeRef: KycIframeLib) => void;
  handleCountrySelection?: (country: CountryCode) => void;
  legalEntityCountry: CountryCode;
  isOnboarding: boolean;
  ssn4Enabled: boolean;
  handleHasInitError?: () => void;
}

const OnboardLegalEntity: React.FC<OnboardLegalEntityProps> = props => {
  const kycIframeId = 'kyc-iframe';
  const classes = useStyles();
  const { getTokenizeFnRef, handleCountrySelection, legalEntityCountry, isOnboarding, ssn4Enabled, handleHasInitError } = props;
  const [initError, setInitError] = useState<WePayInvalidParamsError | undefined>(undefined);

  const setKycIframe = () => {
    if (!createOnboardingIframe) {
      return;
    }
    const returnResult = createOnboardingIframe(kycIframeId, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      country_code: legalEntityCountry,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ssn4_enabled: ssn4Enabled,
    });

    if ('error_code' in returnResult) {
      // we have an instance of WePayInvalidParamsError
      setInitError(returnResult);
      //for reonboarding loader
      if (handleHasInitError) handleHasInitError();
    } else {
      // we have an instance of KycIframeLib
      getTokenizeFnRef(returnResult);
    }
  };

  useEffect(() => {
    setKycIframe();
    // cleanup the iframe div after component unmounting / before re-rendering
    return () => {
      const el = document.getElementById(kycIframeId);
      if (el) {
        el.innerHTML = '';
      }
    };
  }, [legalEntityCountry]);

  return (
    <>
      {(isOnboarding || initError) && (
        <Box px={4} py={0} mx={0} my={2}>
          {isOnboarding && (
            <>
              <Typography variant="h6" gutterBottom>
                Where is your entity located?
              </Typography>
              <ToggleButtonGroup
                exclusive
                onChange={(_event, value) => {
                  if (handleCountrySelection) handleCountrySelection(value);
                }}
                size="small"
                classes={{ groupedHorizontal: classes.separateButtons }}
              >
                <ToggleButton
                  value="US"
                  selected={legalEntityCountry === CountryCode.Us}
                  classes={{ root: classes.rootButton, selected: classes.selectedButton }}
                >
                  UNITED STATES
                </ToggleButton>
                <ToggleButton
                  value="CA"
                  selected={legalEntityCountry === CountryCode.Ca}
                  classes={{ root: classes.rootButton, selected: classes.selectedButton }}
                >
                  CANADA
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}
          {initError && (
            <Box mt={3}>
              Initialization Error!
              <br />
              Error Code: {initError.error_code}
              <br />
              Message: {initError.error_message}
            </Box>
          )}
        </Box>
      )}
      <Box mt={isOnboarding || initError ? 4 : 0} id={kycIframeId}></Box>
    </>
  );
};

export default OnboardLegalEntity;
