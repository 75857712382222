import { gqlClient } from '../../components/AppProvider';
import { CountryCode } from '../../gql-types.generated';
import { mutationUpsertTenant } from '../../gql/MutationUpsertTenantAccount';
import { AppDispatch, AppThunk } from '../../store';
import { decrementRequestsInFlight, incrementRequestsInFlight } from '../app/AppSlice';
import { watchTenantAccount } from '../app/AppActions';
import {
  captureCountry,
  captureMerchantDescription,
  captureMerchantName,
  captureMerchantStatement,
  captureRefundPolicy,
  captureToken,
  captureTOSSignerName,
  persistMerchantOnboardingDataError,
  captureSupportEmail,
} from './MerchantOnboardingSlice';

export const captureTenantAccountCountry =
  (countryCode: CountryCode): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureCountry(countryCode));

export const captureTenantAccountName =
  (name: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureMerchantName(name));

export const captureTenantAccountDescription =
  (description: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureMerchantDescription(description));

export const captureTenantAccountStatement =
  (statement: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureMerchantStatement(statement));

export const captureMerchantRefundPolicy =
  (refundPolicy: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureRefundPolicy(refundPolicy));

export const captureLegalEntityToken =
  (token: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureToken(token));

export const captureTOSSigner =
  (name: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureTOSSignerName(name));

export const captureTenantAccountSupportEmail =
  (email: string): AppThunk =>
  async (dispatch: AppDispatch): Promise<unknown> =>
    dispatch(captureSupportEmail(email));

export const persistMerchantOnboardingData =
  (
    country: CountryCode,
    token: string,
    merchantName: string,
    merchantDescription: string,
    merchantStatement: string,
    refundPolicy: string,
    supportEmail: string,
  ): AppThunk =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementRequestsInFlight());

    try {
      const tenant = await mutationUpsertTenant(
        gqlClient,
        merchantName,
        merchantDescription,
        merchantStatement,
        refundPolicy,
        country,
        token,
        supportEmail,
      );
      if (!tenant) throw new Error('[MerchantOnboardingActions] Merchant account not created');

      console.log('mutationUpsertTenant: ', tenant);

      // refetch merchant account
      dispatch(watchTenantAccount());
      dispatch(decrementRequestsInFlight());
    } catch (error) {
      console.error(error);
      dispatch(persistMerchantOnboardingDataError(error));
      dispatch(decrementRequestsInFlight());
    }
  };
