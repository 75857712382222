import { Box, Button, Paper, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OnboardLegalEntity from '../../components/OnboardLegalEntity';
import { CountryCode } from '../../gql-types.generated';
import { KycIframeLib } from '../../util/WePay';
import { selectTenantAccount } from '../app/AppSlice';
import { UpdateMerchantTenant } from './MerchantReonboardingActions';
import {
  fetchMerchantReonboardingSuccess,
  selectMerchantReonboardingError,
  selectMerchantReonboardingSuccess,
  selectReonboardingMerchant,
} from './MerchantReonboardingSlice';
import ErrorIcon from '@mui/icons-material/Error';
import LoadingMask from '../../components/LoadingMask';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 768,
      alignSelf: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    mutationError: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
      fontWeight: 500,
      wordBreak: 'break-word',
    },
    mutationErrorIcon: {
      marginRight: 4,
    },
  }),
);

let tokenizeFnRef: () => Promise<{ id: string }>;

const MerchantReonboarding: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const reonboardingMerchant = useSelector(selectReonboardingMerchant);
  const tenant = useSelector(selectTenantAccount);
  const merchantReonboardingError = useSelector(selectMerchantReonboardingError);
  const merchantReonboardingSuccess = useSelector(selectMerchantReonboardingSuccess);

  const [updatingTenant, setUpdatingTenant] = useState(false);
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [hasInitError, setHasInitError] = useState(false);
  const hasKycIssue = !!tenant?.company?.verification?.kycIssue;
  const ssn4Enabled = !tenant?.company?.verification?.ssnIssue;
  const merchantCountry = (tenant?.country?.toUpperCase() || 'US') as CountryCode;

  if (!(reonboardingMerchant && hasKycIssue)) {
    history.push('/');
  }

  useEffect(() => {
    setIsIframeLoading(true);

    const iframe = document.getElementById('kyc-iframe_iframe');
    if (iframe) {
      iframe.onload = () => {
        setIsIframeLoading(false);
      };
    } else {
      setIsIframeLoading(false);
    }

    return () => {
      setIsIframeLoading(false);
    };
  }, []);

  useEffect(() => {
    if (merchantReonboardingSuccess && !merchantReonboardingError) {
      history.push('/');
      dispatch(fetchMerchantReonboardingSuccess(false));
    }
    setUpdatingTenant(false);
  }, [merchantReonboardingSuccess, merchantReonboardingError]);

  const getTokenizeFnReference = (iframeRef: KycIframeLib) => {
    tokenizeFnRef = iframeRef.tokenize;
  };

  const onMerchantReonboard = async (legalEntityToken: string): Promise<void> => {
    dispatch(UpdateMerchantTenant(legalEntityToken));
  };

  const submitReonboarding = async (): Promise<void> => {
    try {
      setUpdatingTenant(true);
      const token = await tokenizeFnRef();
      await onMerchantReonboard(token.id);
    } catch (error) {
      // TODO: need better handling
      console.error('Unable to tokenize kyc iframe', error);
      setUpdatingTenant(false);
    }
  };

  const handleHasInitError = () => {
    setHasInitError(true);
  };

  return (
    <Paper className={classes.root}>
      <LoadingMask loading={isIframeLoading && !hasInitError} />
      {merchantReonboardingError && (
        <Box px={3} py={2} display="flex" className={classes.mutationError}>
          <ErrorIcon className={classes.mutationErrorIcon} />
          {merchantReonboardingError.message}
        </Box>
      )}
      <OnboardLegalEntity
        legalEntityCountry={merchantCountry}
        getTokenizeFnRef={getTokenizeFnReference}
        isOnboarding={false}
        ssn4Enabled={ssn4Enabled}
        handleHasInitError={handleHasInitError}
      />
      <Box px={3} py={2} display="flex" justifyContent="flex-end">
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            data-cy="submit-reonboarding"
            onClick={submitReonboarding}
            disabled={updatingTenant}
          >
            Submit
          </Button>
        </div>
      </Box>
    </Paper>
  );
};

export default MerchantReonboarding;
